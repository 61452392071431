// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-contact-examples-js": () => import("./../../../src/pages/contact/examples.js" /* webpackChunkName: "component---src-pages-contact-examples-js" */),
  "component---src-pages-contact-file-upload-js": () => import("./../../../src/pages/contact/file-upload.js" /* webpackChunkName: "component---src-pages-contact-file-upload-js" */),
  "component---src-pages-contact-form-js": () => import("./../../../src/pages/contact/form.js" /* webpackChunkName: "component---src-pages-contact-form-js" */),
  "component---src-pages-contact-thanks-js": () => import("./../../../src/pages/contact/thanks.js" /* webpackChunkName: "component---src-pages-contact-thanks-js" */),
  "component---src-pages-corporate-account-form-js": () => import("./../../../src/pages/corporate-account/form.js" /* webpackChunkName: "component---src-pages-corporate-account-form-js" */),
  "component---src-pages-individual-account-form-js": () => import("./../../../src/pages/individual-account/form.js" /* webpackChunkName: "component---src-pages-individual-account-form-js" */),
  "component---src-pages-tags-index-js": () => import("./../../../src/pages/tags/index.js" /* webpackChunkName: "component---src-pages-tags-index-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-business-page-js": () => import("./../../../src/templates/business-page.js" /* webpackChunkName: "component---src-templates-business-page-js" */),
  "component---src-templates-complaintssafeguarding-page-js": () => import("./../../../src/templates/complaintssafeguarding-page.js" /* webpackChunkName: "component---src-templates-complaintssafeguarding-page-js" */),
  "component---src-templates-contact-js": () => import("./../../../src/templates/Contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-contactus-page-js": () => import("./../../../src/templates/contactus-page.js" /* webpackChunkName: "component---src-templates-contactus-page-js" */),
  "component---src-templates-cookiepolicy-page-js": () => import("./../../../src/templates/cookiepolicy-page.js" /* webpackChunkName: "component---src-templates-cookiepolicy-page-js" */),
  "component---src-templates-corporate-page-js": () => import("./../../../src/templates/corporate-page.js" /* webpackChunkName: "component---src-templates-corporate-page-js" */),
  "component---src-templates-currency-page-js": () => import("./../../../src/templates/currency-page.js" /* webpackChunkName: "component---src-templates-currency-page-js" */),
  "component---src-templates-faq-page-js": () => import("./../../../src/templates/faq-page.js" /* webpackChunkName: "component---src-templates-faq-page-js" */),
  "component---src-templates-index-page-js": () => import("./../../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-individual-page-js": () => import("./../../../src/templates/individual-page.js" /* webpackChunkName: "component---src-templates-individual-page-js" */),
  "component---src-templates-legal-page-js": () => import("./../../../src/templates/legal-page.js" /* webpackChunkName: "component---src-templates-legal-page-js" */),
  "component---src-templates-livefx-page-js": () => import("./../../../src/templates/livefx-page.js" /* webpackChunkName: "component---src-templates-livefx-page-js" */),
  "component---src-templates-open-page-js": () => import("./../../../src/templates/open-page.js" /* webpackChunkName: "component---src-templates-open-page-js" */),
  "component---src-templates-personal-page-js": () => import("./../../../src/templates/personal-page.js" /* webpackChunkName: "component---src-templates-personal-page-js" */),
  "component---src-templates-privacypolicy-page-js": () => import("./../../../src/templates/privacypolicy-page.js" /* webpackChunkName: "component---src-templates-privacypolicy-page-js" */),
  "component---src-templates-product-page-js": () => import("./../../../src/templates/product-page.js" /* webpackChunkName: "component---src-templates-product-page-js" */),
  "component---src-templates-tags-js": () => import("./../../../src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */),
  "component---src-templates-termsandconditions-page-js": () => import("./../../../src/templates/termsandconditions-page.js" /* webpackChunkName: "component---src-templates-termsandconditions-page-js" */),
  "component---src-templates-thankyou-page-js": () => import("./../../../src/templates/thankyou-page.js" /* webpackChunkName: "component---src-templates-thankyou-page-js" */)
}

